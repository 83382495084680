.globalMobilepdf {
  width: 23rem;
  height: 34rem;
  border: 2px solid rgba(0, 0, 0);
  display: flex;
  flex-direction: column;
  /* border-radius: 10px; */
  align-items: center;
  /* box-shadow: rgba(0, 0, 0, 0.45) 0px 5px 15px; */
  gap: 0.3rem;
  margin-top: 0;
  padding-bottom: 2rem;
}

.circleMobilepdf {
  width: 260px;
  height: 114px;
  position: absolute;
  border-radius: 0px 0px 500px 500px;
  background-color: red;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: -10;
}

.quadrosMobilepdf {
  width: 7.5rem;
  height: 10.8rem;
  margin-top: 1rem;
  border-radius: 13px;
  border: 3px solid black;
}

.perfilfotoMobilepdf {
  width: 100%;
  height: 100%;
  max-width: 100%;
  border-radius: 10px;
}

.infocardMobilepdf {
  width: 80%;
  margin-bottom: 0;
  /* gap: 0.3rem; */
  display: flex;
  align-items: center;
  flex-direction: column;
}

.namesMobilepdf {
  font-size: 1.6rem;
  text-align: center;
  font-weight: 600;
  height: 3.7rem;
}

.columnsMobilepdf {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: justify;
  gap: 2.0rem;
}

.columnsMobileCpdf {
  width: 100%;
  /* height: 100%; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: justify;
  gap: 0.5rem;
}

.nomeCursoMobilepdf {
  display: flex;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 600;
  text-align: end;
}

.cardsssMobilepdf {
  width: 100%;
  height: 50%;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.imgamesMobilepdf {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 5px;
  margin-top: -0.85rem;
  margin-bottom: -0.5rem;
}

.logofiec {
  max-width: 50%;
}

.assinatura {
  max-width: 40%;
}

.logonameMobilepdf {
  display: flex;
  text-align: center;
  font-size: 1.4rem;
  max-width: 65%;
  margin-top: -7px;
}

.paragrafosMobilepdf {
  display: flex;
  text-align: justify;
  font-size: 0.8rem;
  font-weight: 600;
  /* height: 10%; */
  width: 80%;
}

.convenioMobilepdf {
  width: 80%;
  margin: 0.5rem 0 0.5rem 21%;
  background-color: #ab49cc;
  border-radius: 50px 10px 10px 50px;
  display: flex;
  align-items: center;
}

.expoMobilepdf {
  margin-left: 10%;
  color: white;
  font-weight: 600;
  padding-top: 8px;
  padding-bottom: 8px;
}

@media(max-width:1100px) {
  .globalMobilepdf {
    width: 30rem;
    height: 55rem;
    margin-top: -1rem;
    gap: 5px;
  }

  .circleMobilepdf {
    width: 22rem;
    height: 8rem;
  }
  
  .quadrosMobilepdf {
    width: 9rem;
    height: 12rem;
    margin-top: 3rem;
  }

  .infocardMobilepdf {
    width: 81%;
    gap: 2px;
  }

  .cardsssMobilepdf {
    margin-top: 0.5rem;
    font-size: 1.3rem;
    gap: 0.5rem;
  }

  .columnsMobileCpdf {
    font-size: 1.3rem;
  }

  .nomeCursoMobilepdf {
    font-weight: 600;
    font-size: 1rem;
  }

  .namesMobilepdf {
    font-size: 2.2rem;
  }

  .imgamesMobilepdf {
    margin-top: -1rem;
}

  .paragrafosMobilepdf {
    font-size: 0.9rem;
    margin: 1rem;
    height: 8%;
  }

  .convenioMobilepdf {
    width: 80%;
    height: 6%;
  }

  .expoMobilepdf {
    padding-top: 4%;
    padding-bottom: 4%;
    font-size: 1.2rem;
  }
}


@media(max-width:600px) {
  .globalMobilepdf {
    width: 22rem;
    height: 40rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-top: 0;
    margin-bottom: 1%;
  }

  .circleMobilepdf {
    width: 13rem;
    height: 7rem;
    margin-top: -2rem;
  }

  .quadrosMobilepdf {
    width: 7.5rem;
    height: 10rem;
    margin-top: 0;
  }

  .perfilfotoMobilepdf {
    max-width: 100%;
    border-radius: 6%;
  }

  .cardsssMobilepdf {
    font-size: 1.1rem;
  }

  .columnsMobileCpdf {
    font-size: 1.1rem;
  }

  .nomeCursoMobilepdf {
    font-weight: 600;
    font-size: 0.8rem;
  }

  .namesMobilepdf {
    font-size: 1.6rem;
    text-align: center;
    font-weight: 600;
  }

  .imgamesMobilepdf {
    margin-top: 0;
  }

  .logonameMobilepdf {
    font-size: 1.1rem;
  }

  .paragrafosMobilepdf {
    font-size: 0.8rem;
    margin: 3%;
    height: 10%;
  }

  .convenioMobilepdf {
    width: 80%;
    height: 6%;
  }

  .expoMobilepdf {
    padding-top: 4%;
    padding-bottom: 4%;
    font-size: 1rem;
  }
}

@media(max-width: 380px) {
  .globalMobilepdf {
    width: 17rem;
    height: 32rem;
    padding-bottom: 1rem;
  }

  .circleMobilepdf {
    width: 12rem;
    height: 6rem;
    margin-top: -2rem;
  }

  .quadrosMobilepdf {
    width: 5.5rem;
    height: 7rem;
    margin-top: -0.5rem;
  }

  .cardsssMobilepdf {
    margin-top: 1%;
    font-size: 0.75rem;
  }

  .columnsMobileCpdf {
    font-size: 0.75rem;
  }

  .nomeCursoMobilepdf {
    font-weight: 600;
    font-size: 0.65rem;
  }

  .paragrafosMobilepdf {
    margin-top: 0.5rem;
    font-size: 0.65rem;
    width: 85%;
    height: 10%;
  }

  .logonameMobilepdf {
    font-size: 1rem;
  }

  .expoMobilepdf {
    margin-left: 10%;
    color: white;
    font-weight: 700;
    font-size: 13px;
  }

  .convenioMobilepdf {
    width: 80%;
    height: 6%;
    margin-bottom: 0;
  }
}