.form {
  position: relative;
  width: fit-content;
}

.input {
  color: #fff;
  font-size: 0.9rem;
  background-color: transparent;
  width: 100%;
  box-sizing: border-box;
  padding-inline: 0.5em;
  padding-block: 0.7em;
  border: none;
  border-bottom: white solid white;
}

.bife {
  color: white;
  display: flex;
  position: relative;
  top: 5px;
  width: fit-content;
  height: fit-content;
  transition: ease-in-out 0.2s;
}

.input-border {
  position: absolute;
  background: white;
  color: "white";
  width: 0%;
  height: 2px;
  bottom: 0;
  left: 0;
  transition: 0.3s;
}

input:hover {
  background: transparent;
}

input:focus {
  outline: none;
}

input:focus~.input-border {
  width: 100%;
}

.form:focus-within .bife {
  color: white;
  position: relative;
  translate: -10px 20px 20px;
  transform: rotate(90deg);
}