.Criaload{
    width: 100px;
    height: 100px;
    background:red;
  
  }
  @media (max-height: 600) {
    .Criaload{
      width: 100px;
      height: 100px;
    }
  }
  
  .Criadivanimation{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    overflow: hidden;
    position: absolute;
    z-index: 1000;
    /* overflow: hidden; */
  }