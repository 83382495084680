.card2 {
  width:18%;
  height:63%;
  background:white;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
 }
 
 .inf{
  display: flex;
  flex-direction:column ;
  justify-content: center;
  width: 100%;
  height:88%;
 }
 .card2 .card-border-top {
  width: 62%;
  height: 4%;
  background:#ff6961;
  margin: auto;
  border-radius: 0px 0px 15px 15px;
 }
 .card2 .card-list-top {
  width: 62%;
  height: 4%;
  background:#ff6961;
  margin: auto;
  border-radius: 0px 0px 15px 15px;
 }
 
 .card2 span {
  font-weight: 400;
  color:black;
  text-align: center;
  display: block;
  padding-top: 10px;
  font-size:1.8rem;
 }
  .buttons2{
    width:40%;
    height:10%;
  display: block;
  margin: auto;
  border-radius:10px;
  border: none;
  background: #ff6961 ;
  color: white;
  font-weight: 600;
  transition:0.4s ease-in;
  }
  .buttons2:hover{
    transform: translateY(-25%);
  }
 .card2 .job {
  font-weight: 400;
  color:black;
  display: block;
  text-align: center;
  padding-top: 3px;
  font-size: 12px;
 }
 
 .card2 .img {
  margin-top:2vh;
  width:48%;
  height:40%;
  border: 2px solid black;
  border-radius: 15px;
  margin: auto;
  margin-top: 25px;
 }

 .imagem{
  max-width:12.0rem;
  margin-left:4vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top:5%;
 }

  .funcao{
    width:80%;
    height:6%;
    color:white;
    font-weight:600;
    margin-top:2vw;
    margin-left:3.5vw;
    border-radius:10px 0px 0px 10px;
    background-color:#ff6961;;
  }
  .pefil{
    max-width:88%;
    margin-left:7%;
    margin-top:16%;
  }