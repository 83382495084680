.globalMobile {
  width: 22rem;
  height: fit-content;
  border: 2px solid rgba(0, 0, 0, 0.45);
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 5px 15px;
  gap: 0.2rem;
  margin-top: 0;
  padding-bottom: 1rem;

}

.circleMobile {
  width: 250px;
  height: 125px;
  position: absolute;
  border-radius: 0px 0px 500px 500px;
  background-color: red;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: -10;
}

.quadrosMobile {
  width: 6rem;
  height: 8.5rem;
  margin-top: 2rem;
  border-radius: 10px;
  border: 2px solid black;
}

.perfilfotoMobile {
  width: 100%;
  height: 100%;
  max-width: 100%;
  border-radius: 10px;
}

.infocardMobile {
  width: 80%;
  margin-top: 0.2rem;
  margin-bottom: 0;
  gap: 0.5rem;
  height: 30%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.namesMobile {
  font-size: 1.6rem;
  text-align: center;
  font-weight: 600;
  height: 3.7rem;
}

.columns {
  width: 100%;
  display: flex;
  justify-content: space-around;
  height: 10%;
}

.columnsMobile {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: justify;
  gap: 2.0rem;
}

.columnsMobileC {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: justify;
  gap: 0.5rem;
}

.nomeCursoMobile {
  display: flex;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 600;
  text-align: end;
}

.cardsssMobile {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
}

.imgamesMobile {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  margin-top: -0.5rem;
}

.logofiec {
  width: 45%;
}

.assinatura {
  width: 40%;
}

.logonameMobile {
  display: flex;
  text-align: center;
  font-size: 1.5rem;
  max-width: 75%;
  margin-bottom: 0.5rem;
}

.paragrafosMobile {
  display: flex;
  text-align: justify;
  font-size: 0.75rem;
  font-weight: 700;
  height: 10%;
  width: 80%;
}

.convenioMobile {
  width: 80%;
  margin: 0.5rem 0 0.5rem 21%;
  background-color: #ab49cc;
  border-radius: 50px 10px 10px 50px;
  display: flex;
  align-items: center;
}

.expoMobile {
  margin-left: 10%;
  color: white;
  font-weight: 600;
  padding-top: 8px;
  padding-bottom: 8px;
}

@media(max-width:1100px) {
  .globalMobile {
    width: 24rem;
    /* height: 55rem; */
    margin-top: -1rem;
    gap: 5px;
  }

  .circleMobile {
    width: 22rem;
    height: 8rem;
  }

  .quadrosMobile {
    width: 8rem;
    height: 11.5rem;
    margin-top: 3rem;
  }

  .infocardMobile {
    width: 81%;
    gap: 2px;
  }

  .cardsssMobile {
    margin-top: 0.5rem;
    font-size: 1.3rem;
    gap: 0.5rem;
  }

  .columnsMobile {
    font-size: 1.1rem;
  }

  .columnsMobileC {
    font-size: 1.1rem;
  }

  .nomeCursoMobile {
    font-weight: 600;
    font-size: 0.9rem;
  }

  .imgamesMobile {
    margin-top: -1rem;
  }

  .logofiec {
    width: 40%;
  }

  .assinatura {
    width: 40%;
  }

  .paragrafosMobile {
    font-size: 0.9rem;
    margin: 1rem;
    height: 8%;
  }

  .expoMobile {
    font-size: 1.2rem;
  }
}


@media(max-width:700px) {
  .globalMobile {
    width: 20rem;
    /* height: 40rem; */
    padding-top: 2rem;
    margin-top: 0;
    margin-bottom: 1%;
  }

  .circleMobile {
    width: 13rem;
    height: 7rem;
    margin-top: -2rem;
  }

  .quadrosMobile {
    width: 6.3rem;
    height: 9rem;
    margin-top: 0;
  }

  .perfilfotoMobile {
    max-width: 100%;
    border-radius: 6%;
  }

  .cardsssMobile {
    font-size: 1rem;
  }

  .columnsMobileC {
    font-size: 1rem;
  }

  .nomeCursoMobile {
    font-weight: 600;
    font-size: 0.75rem;
  }

  .namesMobile {
    font-size: 1.4rem;
    text-align: center;
    font-weight: 600;
    height: 3.1rem;
  }

  .imgamesMobile {
    margin-top: -2%;
  }

  .logonameMobile {
    font-size: 1.2rem;
  }

  .paragrafosMobile {
    font-size: 70%;
    margin: 0;
    height: 10%;
  }

  .expoMobile {
    font-size: 1rem;
  }
}

@media(max-width: 400px) {
  .globalMobile {
    width: 17rem;
    /* height: 32rem; */
  }

  .circleMobile {
    width: 12rem;
    height: 6rem;
    margin-top: -2rem;
  }

  .quadrosMobile {
    width: 5rem;
    height: 7rem;
    margin-top: -0.5rem;
  }

  .cardsssMobile {
    margin-top: 1%;
  }

  .columnsMobile {
    font-size: 0.75rem;
  }

  .columnsMobileC {
    font-size: 0.75rem;
  }

  .nomeCursoMobile {
    font-weight: 600;
    font-size: 0.6rem;
  }

  .paragrafosMobile {
    font-size: 0.65rem;
    width: 85%;
  }

  .logonameMobile {
    font-size: 1rem;
  }

  .expoMobile {
    margin-left: 10%;
    color: white;
    font-weight: 700;
    font-size: 13px;
  }

}