.CheckOne {
    --webkit-appearence: none;
    width: 2em;
    height: 2em;
    background-color: white;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid #ddd;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    margin-left: auto;
    margin-right: 5%;
    margin-top: 2%;
}

.CheckOne:checked {
    background-color: #0d467e;
}

.CheckTwo {
    --webkit-appearence: none;
    width: 2em;
    height: 2em;
    background-color: white;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid #ddd;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    margin-left: 5%;
    margin-right: auto;
    margin-top: 2%;
}

.CheckTwo:checked {
    background-color: #0d467e;
}

.CheckTree {
    --webkit-appearence: none;
    width: 2em;
    height: 2em;
    background-color: white;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid #ddd;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    margin-left: 5%;
    margin-right: auto;
    margin-top: 2%;
}

.CheckTree:checked {
    background-color: #0d467e;
}