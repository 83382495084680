.cardM {
  width: 18rem;
  height: 28rem;
  background: white;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border: 0.5px solid black;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 88%;
}

.cardM .border-top {
  width: 62%;
  height: 5%;
  background: #ff6961;
  margin: auto;
  border-radius: 0px 0px 15px 15px;
}

.cardM .card-list-top {
  width: 62%;
  height: 4%;
  background: #ff6961;
  margin: auto;
  border-radius: 0px 0px 15px 15px;
}

.cardM span {
  font-weight: 300;
  color: black;
  text-align: center;
  display: block;
  padding-top: 10px;
  font-size: 1.8rem;
}

.buttons2 {
  width: 40%;
  height: 10%;
  display: block;
  margin: auto;
  border-radius: 10px;
  border: none;
  background: #ff6961;
  color: white;
  font-weight: 600;
  transition: 0.4s ease-in;
}

.buttons2:hover {
  transform: translateY(-25%);
}

.card2 .job {
  font-weight: 400;
  color: black;
  display: block;
  text-align: center;
  padding-top: 3px;
  font-size: 12px;
}

.cardM .fotoss {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 2vh;
  width: 50%;
  height: 44%;
  border: 2px solid black;
  border-radius: 15px;
  margin: auto;
  margin-top: 25px;
}

.logoss {
  width: 12rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.targetM {
  justify-content: center;
  align-items: center;
  width: 80%;
  color: white;
  font-weight: 600;
  margin-top: 1.5rem;
  margin-left: 20%;
  border-radius: 10px 0px 0px 10px;
  background-color: #ff6961;
}

.targetM p {
  font-size: 16px;
  padding: 4px 0 4px 20px;
  margin-top: 4px;
  font-weight: 600;
}


.user {
  height: 70%;
}