
.container{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
  }
  .Menu {
    width: 30%;
    height: 10%;
    display: flex;
    justify-content: flex-start;
    background-color: black;
    align-items: center;
    color: blue;
    }
  
    .Menu::before {
      content: "Filtros";
      margin-left: 10px;
    }
  
  
  