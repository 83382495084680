.form__group {
  position: relative;
  padding: 20px 0 0;
  width: 100%;
  max-width: 580px;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: none;
  border-bottom: 2px solid #9b9b9b;
  outline: 0;
  font-size: 17px;
  color: black;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
}

.form__field::placeholder {
  color: transparent;
}

.form__field:placeholder-shown~.form__label {
  font-size: 17px;
  cursor: text;
  top: 20px;
  color: var(--background);
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 17px;
  color: var(--background);
  pointer-events: none;
}

@media (max-width: 908px) {
  .form__field {
    font-size: 14px;
  }
  .form__label {
    font-size: 14px;
  }
}

.form__field:focus {
  padding-bottom: 6px;
  font-weight: 500;
  border-width: 3px;
  border-image: linear-gradient(to right, #3a3f86, #5056ac);
  border-image-slice: 1;
}

.form__field:focus~.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 17px;
  color: var(--background);
  font-weight: 600;
}

/* reset input */
.form__field:required,
.form__field:invalid {
  box-shadow: none;
}