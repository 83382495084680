.a{
  width:100%;
  height:180%;
  border:2px solid black;
  display:flex;
  flex-direction: column;
  border-radius:10px;
  align-items: center;
 
}

.circlexx{
  width:90%;
  height: 15%;
  border-radius: 0px 0px 200px 2000px ;
  background-color:red;


}

.quadrosxxx{
  z-index:6;
  width:35%;
  margin-top:0%;
  border-radius:10px;
  height:25%;
  border:2px solid black;
}

.perfilfoto{
  max-width: 100%;
  border-radius:6%;

}

.infocardxxx{
    width:70%;
    margin-top:3%;
    gap:1rem;
    height:50%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.names{
  font-size:1.6rem;
  text-align: center;
  font-weight:600;
}

.columns{
  width:100%;
  display: flex;
  justify-content:space-around;
  height:10%;
}

.columns1{
  width:89%;
  height:20%;
  display: flex;
  justify-content:space-between;
  gap: 2.0rem ;
}

.cardsss{
  width:100%;
  height:55%;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.imgames{
  display:flex;
  justify-content: center;
  margin-top:-8%;
  width: 100%;
}
.logofiec{
  max-width:50%;
}
.assinatura{
  max-width:40%;
}

.logoname{
  text-align: center;
  margin-left:-45% ;
  width:50%;
}

.paragrafos{
  font-size:0.7rem;
  margin-top:2%;
  font-weight:700;
  margin-left:-26%;
  height:10%;
  width:60%;
}

.convenioxx{
  width:80%;
  height:6%;
  margin-top:7%;
  background-color:#ab49cc;
  border-radius:50px 10px 10px 50px;
  margin-left:21%;

  display:flex;
  align-items: center;
}
.expo{
  margin-left:10%;
  color: white;
  font-weight:600;
}

@media(min-width:800px){
  .a{
    width:36%;
    height:86%;
    border:2px solid black;
    display:flex;
    flex-direction: column;
    border-radius:10px;
    align-items: center;

  }

  .circlexx{
    width:65%;
    height: 16%;
    border-radius: 0px 0px 1000px 1000px ;
    background-color:red;
  
  
  }
  .as{
    width:100%;
    height:100%
  }
  .circle{
    width:60%;
    height: 15%;
    border-radius: 0px 0px 500px 500px ;
    background-color:red;

  
  }
  .perfilfoto{
    max-width: 100%;
    border-radius:6%;
  
  }

  .quadros{
  width:35%;
  margin-top: -19%;
  border-radius:10px;
  height:25%;
  border:2px solid black;
}



}