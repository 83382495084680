.main {
  width: 100rem;
  height: 55vh;
  display: flex;
  justify-content: left;
  align-items: center;
}

.card {
  width: 100%;
  height: 100%;
  margin-left: 2.5%;
}

.flex__3 {
  width: 100%;
  position: absolute;
  border: solid 2px;
  height: 100%;
  display: flex;
  justify-content: left;
}

.flex__4 {
  position: absolute;
  left: 105%;
  width: 100%;
  height: 100%;
  border: solid 2px;
  display: flex;
  align-items: center;
  flex-direction: left;

}

.roxopdf {
  height: 100%;
  border-radius: 0px 180px 180px 0px;
  width: 9rem;
  background-color: #ab49cc;
  z-index: -1;
}

.perfilpdf {
  width: 7.5rem;
  height: 10.8rem;
  margin-left: -5rem;
  margin-top: 6.5rem;
  border: 1px solid black;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;
  border-radius: 12px;
}

.perfilphotospdf {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.column1 {
  width: 89%;
  height: 20%;
  display: flex;
  justify-content: space-between;
  gap: 2.0rem;
}

.info-cardpdf {
  width: 18rem;
  height: 100%;
  margin-left: 2rem;
  margin-top: 3rem;
  display: flex;
  gap: 2.0rem;
  flex-direction: column;
}

.nomepdf {
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 600;
  width: 18rem;
}

.descpdf {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.0rem;
}

.column {
  width: 90%;
  height: 20%;
  display: flex;
  justify-content: space-between;
  gap: 2.0rem;

}

.cardpdf {
  height: 95mm;
  width: 158mm;
  position: relative;
  perspective: 800px;
}

.logopdf {
  width: 100%;
  /* height:80%; */
  margin-top: 4%;
}

.fotopdf {
  max-width: 90%;
  margin-left: -1%;
}

.cursopdf {
  width: 6%;
  margin-left: 2rem;
  height: 80%;
  background-color: #ab49cc;
  border-radius: 0px 0px 30px 30px;
}

.turnuss {
  margin-top: 580%;
  transform: rotate(-90deg);
  z-index: 1;
  font-size: 1.1rem;
  color: white;
  font-weight: 600;
  white-space: nowrap;
}

.paragrafopdf {
  width: 23rem;
  height: 75%;
  text-align: left;
  margin-right: 2rem;
  font-weight: 700;
}

.infobackpdf {
  display: flex;
  flex-direction: column;
  width: 20rem;
  height: 80%;
  margin-right: 1rem;
}

.title {
  font-size: 1.3rem;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
}

.card__side {
  width: 100%;
  height: 100%;
  border-radius: 3.18mm;
  position: absolute;
  top: 0;
  left: 0;
  backface-visibility: hidden;
  transition: transform 0.7s ease-out;
  cursor: pointer;
  padding: 10px;
}

.asspdf {
  margin-top: 5rem;
}

.card__side__photo {
  width: 1.4cm;
  height: 1.4cm;
  position: absolute;
  left: 12px;
  bottom: 15px;
  background: grey;
  border-radius: 8%;
}