.flex__1C {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: left;
}

.flex__2C {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: left;

}

.roxoC {
  height: 100%;
  border-radius: 0px 180px 180px 0px;
  width: 25%;
  background-color: #ab49cc;
}

.fotoperfilstnC {
  z-index: 4;
  width: 20%;
  height: 50.3%;
  margin-left: -10%;
  margin-top: 15%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;

}

.fotopefilsC {
  width: 100%;
  height: 100%;
  min-width: 100%;
}


.columnC1 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.nomeCursoC {
  display: flex;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 700;
  text-align: end;
}

.info-cardtC {
  width: 45%;
  height: 95%;
  margin-left: 9%;
  margin-top: 6%;
  display: flex;
  gap: 2.0rem;
  flex-direction: column;
}

.nomeC {
  width: 100%;
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 600;
}

.descC {
  width: 100%;
  height: 40%;
  display: grid;
  gap: 1.0rem;
}

.columnC {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  gap: 2.0rem;
}

.cardC {
  height: 100%;
  width: 70%;
  /* position: relative;
  perspective: 20%; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.logoC {
  width: 100%;
  height: 80%;
  margin-top: 1%;
}

.fotoC {
  max-width: 100%;
  margin-left: -1%;
}

.cursotC {
  width: 6%;
  margin-left: 4%;
  height: 90%;
  background-color: #ab49cc;
  border-radius: 0px 0px 30px 30px;
}

.turnotC {
  position: relative;
  transform: rotate(-90deg);
  top: 80%;
  z-index: 1;
  font-size: 1.2rem;
  color: white;
  font-weight: 600;
  white-space: nowrap;
}

.paragrafoC {
  width: 73%;
  height: 75%;
  text-align: justify;
  margin-right: 3%;
  font-weight: 700;
}

.infobackC {
  display: flex;
  flex-direction: column;
  width: 62%;
  height: 90%;

}

.titleC {
  font-size: 1.3rem;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
}

.card__sideC {
  width: 40rem;
  height: 23rem;
  display: flex;
  border-radius: 3.18mm;
  position: absolute;
  backface-visibility: hidden;
  transition: transform 0.7s ease-out;
  /* cursor: pointer; */
  padding: 10px;
}

.assC {
  margin-top: 20%;
}

.card__side__photoC {
  width: 1.4cm;
  height: 1.4cm;
  position: absolute;
  left: 12px;
  bottom: 15px;
  background: grey;
  border-radius: 8%;
}

.card__side_frontC {
  display: flex;
  background: linear-gradient(90deg, white 0%, #fff9f9 100%);
  transform: rotateY(0deg);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.card__side_backC {
  display: flex;
  background: linear-gradient(-90deg, white 0%, #fff9f9 100%);
  transform: rotateY(-180deg);
  color: black;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.card__side__name-bankC {
  font-family: Inter, sans-serif;
  font-weight: 500;
  position: relative;
  font-size: 22px;
  margin-left: 8px;
  color: white;
}

.card__side__name-bankC::after {
  content: "Universal Bank";
  position: absolute;
  font-size: 6px;
  top: 105%;
  left: 21%;
  color: #635c77;
}

.card__side__name-bankC::before {
  content: "₴";
  position: absolute;
  top: 0;
  right: 0;
  color: #635c77;
}

.card__side__chipC {
  width: 1.3cm;
  height: 1cm;
  margin-left: 22px;
  margin-top: -35px;
  background: rgb(226, 175, 35);
  border-radius: 8px;
}

.card__side__chipC:after {
  content: "";
  display: block;
  position: absolute;
  height: 24px;
  width: 24px;
  top: 80px;
  right: 15px;
  transform: scale(1.3);
}

.card__side__name-personC {
  text-transform: uppercase;
  font-family: Roboto Mono, sans-serif;
  font-size: 14px;
  margin-bottom: 10px;
  margin-left: 20px;
  position: relative;
  display: block;
  color: white;
}

.card__side__name-personC::before {
  content: "";
  display: block;
  position: absolute;
  width: 45px;
  aspect-ratio: 1 / 1;
  background: red;
  bottom: -10px;
  right: 0px;
  border-radius: 50%;
}

.card__side__name-personC::after {
  content: "";
  display: block;
  position: absolute;
  width: 45px;
  aspect-ratio: 1 / 1;
  background: orange;
  bottom: -10px;
  right: 23px;
  border-radius: 50%;
}

.card__side__blackC {
  background: black;
  width: 100%;
  height: 50px;
  border-radius: 3.18mm 3.18mm 0 0;
  position: absolute;
  top: 0;
  right: 0;
}

.card__side__numberC {
  font-size: 18px;
  font-family: Roboto Mono, sans-serif;
  color: #eeeeee;
  margin: 45px 0px 15px 10px;
}

.card__side__other-numbersC {
  font-family: Roboto Mono, sans-serif;
  color: #eeeeee;
  display: block;
  margin-left: 10px;
  font-size: 12px;
  backface-visibility: hidden;
  position: relative;
}

.card__side__other-numbersC::after {
  color: #635c77;
  position: absolute;
  font-size: 8px;
  left: 0;
  bottom: 60px;
}

.card__side__other-numbers_1C::after {
  content: "СТРОК";
}

.card__side__other-numbers_2C::after {
  content: "КОД";
}

.card__side__other-infoC {
  color: #635c77;
  font-size: 4px;
  text-align: center;
  font-family: Roboto Mono, sans-serif;
  position: absolute;
  bottom: 10px;
  left: 38px;
  backface-visibility: hidden;
}

.card__side__debitC {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.8cm;
  height: 1cm;
  border-radius: 1cm;
  background: #c0c0c0;
  position: absolute;
  right: 12px;
  bottom: 25px;
  font-family: Inter;
  color: #666666;
}

.card__side__debitC::after {
  content: "";
  display: block;
  position: absolute;
  background: rgba(166, 163, 163, 0.7);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  right: 0;
}

.cardC:hover .card__side_frontC {
  transform: rotateY(180deg);
}

.cardC:hover .card__side_backC {
  transform: rotateY(0deg);
}

@media(max-height: 700px) {
  .card__sideC {
    width: 30rem;
    height: 17rem;
    margin-top: 2rem;
  }

  .card__side_frontC {
    width: 30rem;
    height: 17rem;
    margin-top: 2rem;
  }

  .card__side_backC {
    width: 30rem;
    height: 17rem;
    margin-top: 2rem;
  }

  .nomeCursoC {
    font-size: 0.75rem;
  }

  .nomeC {
    width: 14.5rem;
    font-size: 1.3rem;
  }
  .paragrafoC {
    margin-right: 4%;
    margin-left: 2%;
    font-size: 0.9rem;
  }
  .titleC {
    font-size: 1.1rem;
  }
  .assC {
    margin-top: 10%;
  }
  .logoC {
    height: 70%;
  }
  .descC {
    font-size: 0.9rem;
    gap: 0.7rem;
  }

  .info-cardtC {
    margin-left: 1.5rem;
    margin-right: 1rem;
    margin-top: 1rem;
    gap: 1rem;
  }
  .cursotC {
    font-size: 0.9rem;
  }
}

@media(max-height: 330px) {
  .card__sideC {
    width: 27rem;
    height: 15rem;
    margin-top: 3rem;
  }

  .card__side_frontC {
    width: 27rem;
    height: 15rem;
    margin-top: 3rem;
  }

  .card__side_backC {
    width: 27rem;
    height: 15rem;
    margin-top: 3rem;
  }
  .nomeCursoC {
    font-size: 0.75rem;
  }
  .nomeC {
    width: 13rem;
    font-size: 1.15rem;
  }
  .paragrafoC {
    margin-top: -3%;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    font-size: 0.8rem;
  }
  .titleC {
    font-size: 1rem;
  }
  .assC {
    margin-top: 15%;
  }
  .descC {
    font-size: 0.9rem;
    gap: 0.7rem;
  }

  .info-cardtC {
    margin-left: 1.5rem;
    margin-right: 1rem;
    margin-top: 1rem;
    gap: 1rem;
  }
  .cursotC {
    font-size: 0.9rem;
  }
}