.button {
  padding: 15px 25px;
  border: unset;
  border-radius: 15px;
  color: white;
  z-index: 1;
  background: #3a3f86;
  position: relative;
  font-weight: 1000;
  font-size: 17px;
  -webkit-box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  transition: all 250ms;
  overflow: hidden;
  align-items: center;
}

.button:hover {
  cursor: pointer;
}

.button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  /* border-radius: 15px; */
  background-color: var(--amarelo);
  z-index: -1;
  -webkit-box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  transition: all 250ms
}

.button::before :active {
  background-color: var(--azul);
}

.button:placeholder-shown~.button {
  font-size: 17px;
  cursor: text;
  top: 20px;
  color: var(--background);
}

.button:hover ::target-text {
  color: #e8e8e8;
}

.button:hover::before {
  width: 100%;
}