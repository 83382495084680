
.loading {
    width: 35px;
    min-height: 35px;
    aspect-ratio: 1;
    --_g: no-repeat radial-gradient(farthest-side, #000 94%, #0000);
    background:
        var(--_g) 0 0,
        var(--_g) 100% 0,
        var(--_g) 100% 100%,
        var(--_g) 0 100%;
    background-size: 40% 40%;
    animation: l38 .5s infinite;

}

@keyframes l38 {
    100% {
        background-position: 100% 0, 100% 100%, 0 100%, 0 0
    }
}