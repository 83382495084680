.global{
  width:90%;
  height:280%;
  border:2px solid black;
  display:flex;
  flex-direction: column;
  border-radius:10px;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.circle{
  width:81%;
  height: 18%;
  border-radius: 0px 0px 500px 500px ;
  background-color:red;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.quadros{
  width:35%;
  margin-top: -19%;
  border-radius:10px;
  height:35%;
  border:2px solid black;
}
.perfilfoto{
  width:100%;
  height: 100%;
  max-width:100%;

}
.infocard{
    width:70%;
    margin-top:3%;
    gap: 1.0rem;
    height:30%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.names{
  font-size:1.6rem;
  text-align: center;
  font-weight:600;
}
.columns{
  width:100%;
  display: flex;
  justify-content:space-around;
  height:10%;
}
.columns1{
  width:89%;
  height:20%;
  display: flex;
  justify-content:space-between;
  gap: 2.0rem ;
}
.cardsss{
  width:100%;
  height:55%;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.imgames{
  display:flex;
  justify-content: center;
  margin-top:-8%;
  width: 100%;
}
.logofiec{
  max-width:50%;
}
.assinatura{
  max-width:40%;
}
.logoname{
  text-align: center;
  margin-left:-42% ;
  width:50%;
}
.paragrafos{
  text-align:justify;
  font-size:0.7rem;
  margin-top:4%;
  font-weight:700;
  margin-left:30%;
  height:10%;
  width:60%;
}
.convenio{
  width:80%;
  height:6%;
  margin-top:7%;
  background-color:red;
  border-radius:50px 10px 10px 50px;
  margin-left:20%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display:flex;
  align-items: center;
}
.expo{
  margin-left:10%;
  color: white;
  font-weight:600;
}
@media(min-width:800px){
  .global{
    width:20vw;
    height:74vh;
    border:2px solid black;
    display:flex;
    flex-direction: column;
    border-radius:10px;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  .circle{
    width:60%;
    height: 15%;
    border-radius: 0px 0px 500px 500px ;
    background-color: red;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  .perfilfoto{
    max-width: 100%;
    border-radius:6%;
  }
  .quadros{
  width:35%;
  margin-top: -19%;
  border-radius:10px;
  height:25%;
  border:2px solid black;
}
}