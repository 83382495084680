#rm {
    width: 100%;
    height: 100%;
    border: 0;
}

#nome {
    width: 100%;
    height: 100%;
    font-size: 1rem;
    font-weight: 600;
    border: 0;
    text-align: center;
}

#cumpriu {
    width: 100%;
    height: 100%;
    border: 0;
}

.selectApto {
    width: 100%;
    height: 100%;
    border: 0;
    text-align: center;
}