.cardCria {
  width: 18rem;
  height: fit-content;
  /* height: 32rem; */
  background: white;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border: 1px solid black;
  padding-bottom: 0.8rem;
}

.infCria {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.card-border-topCria {
  width: 13rem;
  height: 2.5rem;
  background: #ff6961;
  margin: auto;
  border-radius: 0px 0px 30px 30px;
}

.nomeCria {
  width: 90%;
  font-weight: 800;
  color: black;
  text-align: center;
  display: block;
  text-transform: capitalize;
  height: 3.8rem;
}

.imgCria {
  width: 7rem;
  height: 10rem;
  border: 2px solid black;
  border-radius: 15px;
  margin: auto;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.imgCria img {
  width: 7rem;
  height: 10rem;
  border-radius: 15px;
}

.infDadosCriar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  text-transform: capitalize;
}

.infDadosCriar span {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 13px;
}

.infDadosCriar p {
  display: flex;
  font-weight: 700;
  font-size: 15px;
}

.infDadosCriar h4 {
  display: flex;
  font-weight: 700;
  font-size: 15px;
  color: red;
}

.imagemCria {
  width: 12rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
}

.funcaoCria {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.funcaoCria p {
  width: 70%;
  height: 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #ff6961;
  font-size: 16px;
  font-weight: 700;
  padding-left: 2rem;
  color: white;
  border-radius: 20px 0 0 20px;
  text-transform: capitalize;
}