.load{
  width: 100px;
  height: 100px;
  margin-top:15%;
  border-radius:10%;
  background:red;
}

.divanimation{
  width: 100%;
  height: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}